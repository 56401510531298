import React, { useEffect, useState } from "react"

import { useLocation } from "@reach/router"
import queryString from "query-string"
import BoardPage from "../components/board/BoardPage"
import { navigate } from "gatsby"
import routes from "../services/routes"

type State = {
	state: "loading" | "loaded",
	search: {
		id?: string,
	},
}

export default () => {
	const [state, setState] = useState<State>({
		state: "loading",
		search: {},
	})
	const location = useLocation();
	useEffect(() => {
		const search = location.search ? queryString.parse(location.search) : {};
		if ("id" in search) {
			setState({
				state: "loaded",
				search,
			});
		} else {
			navigate(routes.new_board, { replace: true });
		}
	}, []);

	if (state.state === "loading") {
		return <p>Reading board id from url...</p>;
	} else {
		if (!("id" in state.search)) {
			return <p>The url is missing the board id. It should look like /board/?id=abc123.</p>;
		} else {
			return <BoardPage boardId={state.search.id} demo={false} />;
		}
	}
};